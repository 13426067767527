import React from 'react'
import aboutImg1 from '../../assets/images/about/about-img1.png'
import objetividadImg from '../../assets/images/blog/blog-img3.png'
import versatilidadImg from '../../assets/images/history/history2.png'
import portabilidadImg from '../../assets/images/responsive.png'
import atumedidaImg from '../../assets/images/projects/project7.png'


const Differentials = () => {
    return (
        <>
            <section className="overview-area ptb-100 pt-0">
                <div className="container">
                    <div className="overview-box">
                        <div className="overview-content">
                            <div className="content">
                                <h1> <i className="flaticon-tick"></i> Objetividad</h1>
                            </div>
                        </div>

                        <div className="overview-image">
                            <div className="image">
                                <img src={objetividadImg} alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="overview-area ptb-100 pt-0" style={{ backgroundColor: 'rgba(242, 242, 242, .5)' }}>
                <div className="container">
                    <div className="overview-box">
                        <div className="overview-image">
                            <div className="image">
                                <img src={versatilidadImg} alt="about" />
                            </div>
                        </div>

                        <div className="overview-content">
                            <div className="content text-right">
                                <h1> <i className="flaticon-tick"></i> Versatilidad</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="overview-area ptb-100">
                <div className="container">
                    <div className="overview-box">
                        <div className="overview-content">
                            <div className="content">
                                <h1> <i className="flaticon-tick"></i> Portabilidad </h1>
                            </div>
                        </div>

                        <div className="overview-image">
                            <div className="image">
                                <img src={portabilidadImg} alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="overview-area ptb-100 pt-0" style={{ backgroundColor: 'rgba(242, 242, 242, .5)' }}>
                <div className="container">
                    <div className="overview-box">
                        <div className="overview-image">
                            <div className="image">
                                <img src={atumedidaImg} alt="about" />
                            </div>
                        </div>

                        <div className="overview-content">
                            <div className="content text-right">
                                <h1> <i className="flaticon-tick"></i> A tu medida </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Differentials